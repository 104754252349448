import "date-fns";
import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import axios from "axios";
import "./second.css";
import { Link, useHistory } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  doctorDetails,
  appointmentTime,
  appointmentDateByUser,
} from "../actions/Index";

function StepSecond(props) {
  const dispatch = useDispatch();
  const [errorMessage, seterrorMessage] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [buttonValue, setButtonValue] = useState([]);
  const [appointmentDate, setAppointmentDate] = useState();
  const [appTime, setappTime] = useState();
  const history = useHistory();
  const [doctorname, setDoctorname] = useState("");
  const getdoctorID = props.match.params.doctorid;
  const uri = `${process.env.REACT_APP_URL}`;
  //const PatientDates =[];
  let blockDates = [];
  const presentDate = new Date().toISOString().split("T")[0];

  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 30);

  const futureDates = futureDate.toISOString().split("T")[0];

  // console.log(` ${presentDate} future date ${futureDates}  `);
  useEffect(() => {
    axios
      .get(
        `${uri}/Doctor/GetDoctorProfile?DoctorId=${getdoctorID}&hospitalid=1`
      )
      .then(({ data }) => {
        const temp = JSON.parse(data.Data);
        dispatch(doctorDetails(JSON.stringify(temp)));
        setDoctorname(temp);
      })
      .catch((err) => {
        debugger;
        console.warn(err);
      });

    axios
      .get(
        `${uri}/Patient/GetDocAppointAvailableDays?ConsultantId=${getdoctorID}&FromDate=${presentDate}&ToDate=${futureDates}&SplitTime=10&HospId=1`
      )
      .then(({ data }) => {
        // console.log(data);
        //PatientDates = data;
        blockDates = JSON.parse(data.Data)
          ["Table"].filter((d) => {
            if (d.AptStatus == "N") return d;
          })
          .map((b) => {
            let _dt = new Date(b.ApptDate);
            _dt.setHours(0, 0, 0, 0);
            return _dt;
          });
        window.localStorage.setItem("blockDates", blockDates);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [getdoctorID, uri]);

  const handleDateChange = (date) => {
    let [datef, month, year] = [
      date.getDate(),
      date.getMonth() + 1,
      date.getFullYear(),
    ];
    datef = datef < 10 ? "0" + datef : datef;
    month = month < 10 ? "0" + month : month;
    let dateStr = year + "-" + month + "-" + datef;
    let datestrOutput = datef + "/" + month + "/" + year;
    setAppointmentDate(datef + "-" + month + "-" + year);
    axios
      .get(
        `${uri}/Doctor/GetDoctorAppointments?hospitalid=1&Doc_Id=${getdoctorID}&date=${dateStr}`
      )
      .then(({ data }) => {
        console.log(data.SlotInfo);
        if (data.SlotInfo) {
          setButtonValue([]);
          // let temp = JSON.parse(data.SlotInfo);
          let temp = data.SlotInfo;
          for (let i = 0; i < temp.length; i++) {
            setButtonValue((currentTime) => [...currentTime, temp[i]]);
            if (temp[i].FromTime === "06:50 PM") {
              break;
            }
          }
        } else {
          setButtonValue([]);
          seterrorMessage(`No time slot for this Date : ${datestrOutput}`);
          openModal();
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setSelectedDate(date);
  };

  const callMePLZ = (e) => {
    buttonValue.map((value, index) => {
      document.getElementById(`${index}`).classList.remove("active");
      setappTime(e.target.value);
      return e.target.classList.add("active");
    });
  };

  const handleSubmit = (e) => {
    let [date, month, year] = [
      selectedDate.getDate(),
      selectedDate.getMonth() + 1,
      selectedDate.getFullYear(),
    ];
    date = date < 10 ? "0" + date : date;
    month = month < 10 ? "0" + month : month;
    let dateStr = date + "/" + month + "/" + year;

    dispatch(appointmentDateByUser(dateStr));
    if (appTime === undefined) {
      seterrorMessage(
        `You are booikng appointment at ${dateStr} and time is not selected.`
      );
      openModal();
      e.preventDefault();
    } else {
      seterrorMessage(
        `You are booikng appointment at ${dateStr} and time is ${appTime}.`
      );
      openModal();
      // window.localStorage.removeItem("blockDates");
      history.push(`/step-four/doctorid/${getdoctorID}`);
      dispatch(appointmentTime(appTime));
    }
  };

  const visitLink = `/step-third/doctorid/${getdoctorID}`;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const openModal = () => {
    handleShow(true);
  };
  const disableWeekends = (date) => {
    const dateInterditesRaw = [
      new Date(date.getFullYear(), 3, 1),
      new Date(date.getFullYear(), 3, 2),
      new Date(date.getFullYear(), 3, 8),
      new Date(date.getFullYear(), 3, 14),
      new Date(date.getFullYear(), 3, 15),
      new Date(date.getFullYear(), 3, 3),
      new Date(date.getFullYear(), 3, 11),
      new Date(date.getFullYear(), 3, 25),
    ];
    let blockDates = window.localStorage
      .getItem("blockDates")
      ?.toString()
      ?.split(",");
    const dateInterdites = blockDates.map((arrVal) => {
      return new Date(arrVal).getTime();
    });
    //return date.getDay() === 0 || dateInterdites.includes(date.getTime());
    return dateInterdites.includes(date.getTime());
  };
  return (
    <>
      <div id="main-div-fluid">
        <div className="container-fluid text-white bgme pt-2">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-9">
                <div className="row">
                  <div className="mx-2 my-1  step-third-icon">
                    <AddCircleIcon id="step-second-icon" />
                  </div>
                  <div className="text-white" id="smallText">
                    <h4>{doctorname && doctorname[0].Name}</h4>
                    <p>
                      {doctorname && `${doctorname[0].Specialisation}`}&nbsp;&
                      <br />
                      {doctorname && `${doctorname[0].Designation}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="row align-items-center">
                  <div className="button mx-auto">
                    <Link to={visitLink} className="text-white">
                      View Profile
                    </Link>
                    &nbsp; &nbsp;<i className="fas fa-user"></i>
                  </div>
                  <div className="button ml-auto" id="back-button">
                    <button
                      className="btn btn-light rounded-pill px-3 px-md-4 float-md-right my-1"
                      onClick={history.goBack}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container p-sm-2 mt-sm-5 ">
          <form onSubmit={handleSubmit}>
            <div className="row justify-content-around">
              <div
                className="col-md-5 text-center card p-sm-4 mt-sm-0 mt-2"
                id="choose-time-box"
              >
                <h3 className=" pt-sm-0 pt-3">Choose date</h3>
                <hr />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disablePast
                    minDate={presentDate}
                    maxDate={futureDate}
                    margin="normal"
                    id="date-picker-dialog"
                    label="Booking Date"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    shouldDisableDate={disableWeekends}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              {buttonValue.length !== 0 ? (
                <div className="col-md-7 text-center mt-sm-0 mt-2">
                  <div className="row card mx-md-2 p-sm-4" id="Specificheight">
                    <h3 className="heading">Choose Slot</h3>
                    <hr />
                    <div className="another-div">
                      {buttonValue.map((value, index) => {
                        return (
                          <div className="d-inline" key={index}>
                            {value.SlotStatus === "Available" ? (
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Third group"
                              >
                                <input
                                  type="button"
                                  value={`${value.FromTime} - ${value.ToTime}`}
                                  id={index}
                                  onClick={callMePLZ}
                                  className="btn btn-outline-success m-sm-2 m-1"
                                />
                              </div>
                            ) : (
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Third group"
                              >
                                <input
                                  type="button"
                                  id={index}
                                  value={`${value.FromTime} - ${value.ToTime}`}
                                  className="btn btn-danger m-1"
                                  disabled
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="row">
              <div
                className="col-12 text-center my-2 my-sm-4"
                id="step-second-button-row"
              >
                {/* {appointmentDate !== undefined && appTime !== undefined ? (
                  <div className="d-flex justify-content-center">
                    <div className=" col-md-8 text-white show-book-time">
                      <h5 className="mt-2">
                        {appointmentDate === "" && appTime === ""
                          ? setAppointmentDate(
                              "Select date and time for Appointment"
                            )
                          : `Your Appointment Date is ${appointmentDate}, and time is ${appTime}`}
                      </h5>
                    </div>
                  </div>
                ) : (
                  appTime
                )} */}
                <div className="mt-2 mt-sm-4">
                  <button className="btn btn-success btn-lg">
                    Proceed to Book
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Warning !!!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-danger">{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default StepSecond;
